.about-title{
    font-family: sans-serif;
}

.page-section{
    /* height: 100vh; */
    padding: 5%;
}

.backToTopButton{
    position: fixed;
    bottom: 6%;
    right: 2%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: grey;
}

/* skillIcons: */
.skillsIcons{
    width: 4rem;
    height: 4rem;
}

@media only screen and (max-width:600px) {
    .page-container > .page-section{
        flex-direction: column !important;
    }
    .aboutText h2{
        font-size: 1rem;
    }
}