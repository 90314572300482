


body{
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}

p{margin: 0;}


.mainDiv{
    flex: 1;
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
}

.mainBody{
    flex:1
}


.appDark{
    width: 100vw;
    height: 100vh;
    background-color: black;
    /* background-image:url("./images/moon.jpg"); */
    background-repeat: no-repeat;
    background-size:cover;
}

.appLight{
    width: 100vw;
    height: 100vh;
    background-color: white;
    /* background-image:url("./images/sky_1200px.jpg"); */
    background-repeat: no-repeat;
    background-size:cover;
}
