

.firstCard{
    
    padding:8px;
    border:1px solid ;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgb(216, 216, 216);
}


.projectCard{
    height:auto;
    max-width: 50%;
    max-height: 50%;
    padding:8px;
    border:1px solid ;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgb(216, 216, 216);
}

.project-title > a{
    text-decoration: none;
    
}

.project-title{
    display: inline-block;
    position: relative;
    padding-bottom: 2px;
    text-decoration: none;
    margin-top: 10px;
}

.project-title::before{
    content:'';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: rgb(62, 101, 243);
    transition: width 0.25s ease-out;
}

.project-title:hover::before{
    width: 100%;
    
}


.githubicon {
    font-size:3rem !important;
} 
.youtubeicon {
    font-size: 3rem !important;
} 

.icons:hover{
    transform: scale(1.2); 
    transition: transform 0.3s ease-in-out; 
}




/* for small screens: */
@media only screen and (max-width:600px){
    .projects-container > 
    .firstCard{
     width: 100%;
     height: 100%;
     flex-direction: column !important;
    }
    .projects-container > .second-section{
        flex-direction: column !important;
    }
    .second-section{
        
    }
    .projectCard{
        
        max-width: 100%;
        height: auto;
    }
 }

 /* for large screens: */
 @media only screen and (min-width:1500px){
    .projects-container > 
    .firstCard{
        width: 60vw;
    }
    .slidesDiv{
        justify-self: center;
    }
 }