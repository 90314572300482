.icons{
    font-size: 2rem;
}

.icons:hover{
    font-size: 2.2rem;
}

.icons > a > svg{
    color:white !important
}