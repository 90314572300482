.experienceMainDiv{
    border-top: 3mm ridge rgba(150,150,150,0.6)
}

.vertical-timeline-element-title{
    font-weight: 800;
    font-size: 1.2rem;
}

.date{
    color:rgb(147, 146, 146);
    
}